import WcCardTabs from '@components/shared/WcCardTabs'

const AccountTabs = {
  components: {
    WcCardTabs,
  },

  computed: {
    tabs() {
      return [
        {
          name: this.$t('activerecord.models.account.one'),
          route: { name: 'manager-accounts-edit', params: { id: this.account_id } },
        },
        {
          name: this.$t('activerecord.models.account/profile.one'),
          route: { name: 'manager-accounts-profile-edit', params: { account_id: this.account_id } },
        },
        {
          name: this.$t('activerecord.models.contact.one'),
          route: { name: 'manager-accounts-contact-edit', params: { account_id: this.account_id } },
        },
        {
          name: this.$t('activerecord.models.address.one'),
          route: {
            name: 'manager-accounts-address-edit',
            params: { account_id: this.account_id },
          },
        },
        {
          name: this.$t('activerecord.models.organization.other'),
          route: {
            name: 'manager-accounts-organizations',
            params: { account_id: this.account_id },
          },
        },
        {
          name: this.$t('activerecord.models.role.other'),
          route: { name: 'manager-accounts-roles-edit', params: { account_id: this.account_id } },
        },
      ]
    },
  },
}

export default AccountTabs
